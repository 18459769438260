import React, { useEffect, useState } from 'react';
import {config} from '../../utils';
import Lottie from 'lottie-react';
import rankLottie from '../../images/rank-lottie.json';
import gold from '../../images/gold.png';
import silver from '../../images/silver.png';

const Ranking = ({endpoint}) => {
    const [ranking, setRanking] = useState([]);
    const isLoveRank = endpoint === config.API_GET_LOVE_RANK_ENDPOINT;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + endpoint, 
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: localStorage.getItem('token') ?? config.TOKEN,
                    }),
                });
                const data = await response.json();
                if (data && Array.isArray(isLoveRank ? data.love_rank : data.spicy_rank)) {
                    setRanking(isLoveRank ? data.love_rank : data.spicy_rank);
                } else {
                    console.error('Invalid data format:', data);
                    if (data === 'Invalid token') {
                        localStorage.removeItem('token');
                    }
                }
            } catch (error) {
                console.error('Error fetching love sentences:', error);
            }
        };
        fetchData();
    }, [endpoint, isLoveRank]);

    return (
        <div className='rank-container'>
            <Lottie className='rank-lottie' animationData={rankLottie} loop={false}/>
            {ranking !== null && ranking.length > 0 ? (
                <div className='ranking-item-conainer'>
                    {ranking.map((sObj, index) => (
                        <div className='ranking-item' key={index}> 
                            <img src={index === 0 ? gold : silver} alt='rank' />
                            <p>{sObj.username} <span>{ isLoveRank ? sObj.love_counter : sObj.spicy_counter}</span></p>
                        </div>
                    ))}
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};


export default Ranking;