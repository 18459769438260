import Title from "./Title";

const ConfirmationDialog = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="confirmation-dialog-container">
            <div className="confirmation-dialog">
                <Title text=" Mmmh ... " />
                <p>{message}</p>
                <button onClick={onConfirm}>Confirmer</button>
                <button onClick={onCancel}>Annuler</button>
            </div>
        </div>
    );
};

export default ConfirmationDialog;