import React, { useState, useEffect } from "react";
import { config } from "../../utils";
import Info from "../utils/Info";
import Title from "../utils/Title";
import Form from "../utils/Form";

const CreateSentence = ({ endpoint }) => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [infoType, setInfoType] = useState("");

  const sentenceTitle = config.IS_LOVE ? "de lover" : "hot";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTitle("");
    try {
      const response = await fetch(config.API_URL + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify({
          token: localStorage.getItem("token") ?? config.TOKEN,
          sentence: title,
        }),
      });

      if (response.ok) {
        setInfoType(config.INFO_TYPE.SUCCESS);
        setMessage("La phrase a bien été crée");
      } else {
        throw new Error("Failed to create sentence");
      }
    } catch (error) {
      setInfoType(config.INFO_TYPE.ERROR);
      setMessage("La phrase n'as pas pu être crée");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("");
      }, 5000); // Hide message after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <div className="create-sentence-container">
      <Title text={"Phrase " + sentenceTitle} />
      <Form
        title={"Formulaire " + sentenceTitle}
        onSubmit={handleSubmit}
        disabled={!title}
        content={
          "Envoyer une nouvelle phrase " + sentenceTitle + " via ce formulaire."
        }
        buttonText={"Envoyer la phrase"}
        fields={{
          title: {
            type: "text",
            value: title,
            onChange: (e) => setTitle(e.target.value),
            placeholder: "Ta meilleure phrase ;)",
            textarea: true,
          },
        }}
      />
      {message && (
        <Info infoType={infoType} clear={setMessage} text={message} />
      )}
    </div>
  );
};

export default CreateSentence;
