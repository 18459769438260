const config = {
    API_URL: 'https://cassiopee-api.mathias-duprat.fr/api/',
    TOKEN: '',
    USERNAME: '',
    IS_LINKED: false,
    INFO_TYPE: {
        INFO: 'info',
        SUCCESS: 'success',
        ERROR: 'error',
    },
    IS_LOVE: true,
    API_GIVE_LOVE_ENDPOINT: 'give_love',
    API_GET_LOVE_RANK_ENDPOINT: 'get_love_rank',
    API_GIVE_SPICY_ENDPOINT: 'give_spicy',
    API_GET_SPICY_RANK_ENDPOINT: 'get_spicy_rank',
    API_GET_LOVE_ENDPOINT: 'get_love',
    API_GET_SPICY_ENDPOINT: 'get_spicy',
    API_GET_LOVE_DATE_ENDPOINT: 'get_love_date',
    API_GET_SPICY_DATE_ENDPOINT: 'get_spicy_date',
    API_CREATE_LOVE_DATE_ENDPOINT: 'create_love_date',
    API_CREATE_SPICY_DATE_ENDPOINT: 'create_spicy_date',
    API_CREATE_LOVE_SENTENCE_ENDPOINT: 'create_love_sentence',
    API_CREATE_SPICY_SENTENCE_ENDPOINT: 'create_spicy_sentence',
    API_DELETE_LOVE_DATE_ENDPOINT: 'delete_love_date',
    API_DELETE_SPICY_DATE_ENDPOINT: 'delete_spicy_date',
    API_RANDOM_LOVE_DATE_ENDPOINT: 'get_random_love_date',
    API_RANDOM_SPICY_DATE_ENDPOINT: 'get_random_spicy_date',
    API_LIKE_LOVE_DATE_ENDPOINT: 'like_love_date',
    API_LIKE_SPICY_DATE_ENDPOINT: 'like_spicy_date',
    API_UNLIKE_LOVE_DATE_ENDPOINT: 'unlike_love_date',
    API_UNLIKE_SPICY_DATE_ENDPOINT: 'unlike_spicy_date',
    API_CHECK_LOVE_DATE_ENDPOINT: 'check_love_date',
    API_CHECK_SPICY_DATE_ENDPOINT: 'check_spicy_date',
    API_ADD_FRIEND_ENDPOINT: 'add_friend',
    API_REGISTER_ENDPOINT: 'register',
    API_LOGIN_ENDPOINT: 'login_check',
};

const getTimeAgoString = (timestamp) => {
    const now = new Date();
    const past = new Date(timestamp);
    const diff = Math.floor((now - past) / 1000); // Difference in seconds

    if (diff < 60) {
        return `${diff} seconde(s)`;
    } else if (diff < 3600) {
        const minutes = Math.floor(diff / 60);
        return `${minutes} minute(s)`;
    } else if (diff < 86400) {
        const hours = Math.floor(diff / 3600);
        return `${hours} heure(s)`;
    } else {
        const days = Math.floor(diff / 86400);
        return `${days} jour(s)`;
    }
};

const setIsLinked = (isLinked) => {
    config.IS_LINKED = !!isLinked;
    localStorage.setItem('isLinked', isLinked);
}
const setData = (username, token) => {
    config.USERNAME = username;
    config.TOKEN = token;

    localStorage.setItem('username', username);
    localStorage.setItem('token', token);
}


const checkIfUserLiked = (usersLike) => {
    if (usersLike === undefined || usersLike.length === 0) {
        return false;
    }
    const currentUser = localStorage.getItem('username') ?? this.USERNAME;

    for (let i = 0; i < usersLike.length; i++) {
        if (usersLike[i].username === currentUser) {
            return true;
        }
    }
    return false;
}

export {config, setData, setIsLinked, getTimeAgoString, checkIfUserLiked};