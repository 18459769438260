import React, { useState, useEffect } from 'react';
import { config, checkIfUserLiked } from '../../utils';
import unlikeLogo from '../../images/unlike.png';
import likeLogo from '../../images/like.png';
import Info from '../utils/Info';

const LikeDate = ({onSubmit, title, usersLike, isDisabled}) => {
    const [message, setMessage] = useState('');
    const [isLiked, setIsLiked] = useState(false);
    const [infoType, setInfoType] = useState('');

    const endpointLike = config.IS_LOVE ? config.API_LIKE_LOVE_DATE_ENDPOINT : config.API_LIKE_SPICY_DATE_ENDPOINT;
    const endpointUnlike = config.IS_LOVE ? config.API_UNLIKE_LOVE_DATE_ENDPOINT : config.API_UNLIKE_SPICY_DATE_ENDPOINT;

    useEffect(() => {
        setIsLiked(checkIfUserLiked(usersLike));
    }, [usersLike]);

    const handleLike = async () => {
        try {
            const response = await fetch(config.API_URL + endpointLike, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                },
                body: JSON.stringify({
                    token: localStorage.getItem('token') ?? config.TOKEN,
                    title: title
                }),
            });

            if (response.ok) {
                setInfoType(config.INFO_TYPE.INFO);
                setMessage('Le date a bien été liké !');
                onSubmit();
                setIsLiked(true);
            }
        } catch (error) {
            setInfoType(config.INFO_TYPE.ERROR);
            setMessage('Impossible de liker le date :(');
            console.error('Error:', error);
        }
    };

    const handleUnlike = async () => {
        try {
            const response = await fetch(config.API_URL + endpointUnlike, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                },
                body: JSON.stringify({
                    token: localStorage.getItem('token') ?? config.TOKEN,
                    title: title
                }),
            });

            if (response.ok) {
                setInfoType(config.INFO_TYPE.INFO);
                setMessage('Le date a bien été unliké !');
                onSubmit();
                setIsLiked(false);
            }
        } catch (error) {
            setInfoType(config.INFO_TYPE.ERROR);
                setMessage('Impossible d\'unliké le date :(');
            console.error('Error:', error);
        }
    };

    return (
        <div>
            {isLiked ? (
                <img src={unlikeLogo} alt='' onClick={ isDisabled ? () => {}  : handleUnlike }/>
            ) : (
                <img src={likeLogo} alt='' onClick={ isDisabled ? () => {}  : handleLike }/>
            )}
            {message && <Info infoType={infoType} clear={setMessage} text={message} />}
        </div>
    );
};

export default LikeDate;