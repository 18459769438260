import React, { useEffect, useState } from 'react';
import { config, getTimeAgoString } from '../../utils';
import Sentence from './Sentence';
import Title from '../utils/Title';

const AllSentences = ({endpoint}) => {
    const [sentences, setSentences] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + endpoint, 
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: localStorage.getItem('username') ?? config.USERNAME,
                        token: localStorage.getItem('token') ?? config.TOKEN,
                    }),
                });
                const data = await response.json();

                if (data && Array.isArray(config.IS_LOVE ? data.love_sentences : data.spicy_sentences)) {
                    setSentences(config.IS_LOVE ? data.love_sentences : data.spicy_sentences);
                } else {
                    if (data === 'Invalid token') {
                        localStorage.removeItem('token');
                    }
                }
            } catch (error) {
                console.error('Error fetching love sentences:', error);
            }
        };
        
        // Ne déclencher qu'une seule fois
        fetchData();
    }, [endpoint]); // Tableau vide pour exécuter useEffect une seule fois

    return (
        <div>
            <Title text={config.IS_LOVE ? 'Mots d\'amour' : 'Mots coquins'} />
                <div className='ds-card-container'>
                    {sentences !== null && sentences.length > 0 ?
                        sentences.map((sObj, index) => (
                            <Sentence key={index} date={sObj.date} sentence={sObj.sentence}/>
                        )) : (
                            <div></div> // Skeleton here
                        )
                    }
                </div>
        </div>
    );
};

export default AllSentences;
