import React from "react";

const Form = ({ content, disabled, buttonText, title, fields, onSubmit }) => {
  const renderFields = () => {
    return Object.entries(fields).map(([fieldName, field]) => {
      const {
        type,
        value,
        onChange,
        placeholder,
        required,
        textarea = false,
      } = field;

      return textarea ? (
        <textarea
          key={fieldName}
          required={required}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      ) : (
        <input
          key={fieldName}
          required={required}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      );
    });
  };

  return (
    <form className="form-template" onSubmit={onSubmit}>
      <p className="form-template-title">{title}</p>
      <p className="form-template-content">{content}</p>
      <div className="form-template-form">
        {renderFields()}
        <button
          type="submit"
          disabled={disabled}
          className="form-template-button-submit"
        >
          {buttonText}
        </button>
      </div>
    </form>
  );
};

export default Form;
