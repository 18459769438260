import React, { useState, useEffect } from 'react';
import { config } from '../../utils';
import Info from '../utils/Info';
import RandomImage from '../../images/random.png';

const RandomDate = ({endpoint}) => {
    const [randomDate, setRandomDate] = useState('');
    const [infoType, setInfoType] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(config.API_URL + endpoint, 
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: localStorage.getItem('token') ?? config.TOKEN,
                }),
            });
            const data = await response.json();
            if (data && (config.IS_LOVE ? data.love_date : data.spicy_date)) {
                setInfoType(config.INFO_TYPE.INFO);
                setRandomDate(config.IS_LOVE ? data.love_date : data.spicy_date);
            } else {
                console.error('Invalid data format:', data);
            }
        } catch (error) {
            setInfoType(config.INFO_TYPE.ERROR);
            setRandomDate('Impossible de récuperer un date aléatoire');
            console.error('Error fetching date:', error);
        }
    };

    useEffect(() => {
        if (randomDate) {
            const timer = setTimeout(() => {
                setRandomDate('');
            }, 5000); // Hide message after 5 seconds

            return () => clearTimeout(timer);
        }
    }, [randomDate]);

    return (
        <div className='random-date-container'>
            <img
                src={RandomImage}
                alt="Random"
                onClick={handleSubmit}
                />
            {randomDate &&<Info infoType={infoType} clear={setRandomDate} text={randomDate}/>}
        </div>
    );
};

export default RandomDate;