import React, { useState } from 'react';
import { config } from '../../utils';
import deleteLogo from '../../images/delete.png';
import ConfirmationDialog from '../utils/ConfirmationDialog';

const DeleteDate = ({ onSubmit, title }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const deleteDateEndpoint = config.IS_LOVE ? config.API_DELETE_LOVE_DATE_ENDPOINT : config.API_DELETE_SPICY_DATE_ENDPOINT;

    const handleSubmit = async () => {
        try {
            const response = await fetch(config.API_URL + deleteDateEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                },
                body: JSON.stringify({
                    token: localStorage.getItem('token') ?? config.TOKEN,
                    title: title
                }),
            });

            if (response.ok) {
                onSubmit();
                setShowConfirmation(false);
            } else {
                throw new Error('La date n\'a pas pu être supprimée');
            }
        } catch (error) {
        }
    };

    const handleConfirmation = () => {
        setShowConfirmation(true);
    };

    const handleConfirm = () => {
        handleSubmit();
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    return (
        <div>
            <img
                src={deleteLogo}
                alt="Delete"
                onClick={handleConfirmation}
            />
            {showConfirmation &&
                <ConfirmationDialog
                    message="Êtes-vous sûr de vouloir supprimer ce date ?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            }
        </div>

    );
};

export default DeleteDate;