import React, { useState, useEffect } from 'react';
import { config, setData, setIsLinked} from '../../utils';
import Form from '../utils/Form';
import Info from './Info';
import Title from './Title';

const AuthentForm = ({ onAuthSuccess, isRegister, endpoint, title, headerTitle, content, buttonText }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [friendcode, setFriendcode] = useState('');
    const [message, setMessage] = useState('');
    const [infoType, setInfoType] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await fetch(config.API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                },
                body: JSON.stringify({ username, password, friendcode }),
            });
    
            if (response.ok) {
                // Handle successful login
                const data = await response.json();
                onAuthSuccess();
                setData(username, data.token);
                setIsLinked(data.is_linked);

            } else {
                // Handle failed login
                setInfoType(config.INFO_TYPE.ERROR);
                setMessage('Erreur lors de la tentative de connexion');
                throw new Error('Failed to login'); 
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage('');
            }, 5000); // Hide message after 5 seconds

            return () => clearTimeout(timer);
        }
    }, [message]);

    return (

        <div className='auth-form-container'>
        <Title text={headerTitle} />
            <Form 
                title={title}
                content={content}
                buttonText={buttonText}
                fields={{
                    username: {
                        type: 'text',
                        value: username,
                        onChange: (e) => setUsername(e.target.value),
                        placeholder: 'Ton merveilleux pseudo ...',
                        required: true,
                    },
                    password: {
                        type: 'password',
                        value: password,
                        onChange: (e) => setPassword(e.target.value),
                        placeholder: 'Le mot de passe qui va bien !',
                        required: true,
                    },
                }}
                onSubmit={handleSubmit}
            />

            {!isRegister && <a className='register-link' href="/register">Pas encore inscrit ? Creer ton compte ici !</a>}
            {!!isRegister && <a className='register-link' href="/">Deja un compte ? Connecte toi ici !</a>}
            {message && <Info infoType={infoType} clear={setMessage} text={message} />}
        </div>
    );
};

export default AuthentForm;