import React, { useState, useEffect } from 'react';
import Date from './Date';
import { config } from '../../utils';
import ReactPaginate from 'react-paginate';

const PAGE_SIZE = 10; // Nombre de dates par page

const AllDates = ({onSubmit }) => {
    const [dates, setDates] = useState([]);
    const [currentPage, setCurrentPage] = useState(0); // Modifiez ici pour commencer à 0
    const endpoint = config.IS_LOVE ? config.API_GET_LOVE_DATE_ENDPOINT : config.API_GET_SPICY_DATE_ENDPOINT;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: localStorage.getItem('token') ?? config.TOKEN,
                    }),
                });
                const data = await response.json();
                if (data && Array.isArray(config.IS_LOVE ? data.love_date : data.spicy_date)) {
                    setDates(config.IS_LOVE ? data.love_date : data.spicy_date);
                } else {
                    console.error('Invalid data format:', data);
                    if (data === 'Invalid token') {
                        localStorage.removeItem('token');
                    }
                }
            } catch (error) {
                console.error('Error fetching date:', error);
            }
        };
        fetchData();
    }, [endpoint]);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const pageCount = Math.ceil(dates.length / PAGE_SIZE);
    const displayedDates = dates.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);

    
    return (
        <>
            {displayedDates.length > 0 ? (
                <div className='ds-card-container'>
                    {displayedDates.map((sObj, index) => (
                        <Date onSubmit={onSubmit} sObj={sObj} key={index} />
                    ))}
                </div>
            ) : (
                <div></div>
            )}
            {pageCount > 1 && (
                <div className='pagination-container'>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>
            )}
        </>
    );
};

export default AllDates;
