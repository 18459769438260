import React from 'react';
import DeleteDate from './DeleteDate';
import LikeDate from './LikeDate';
import likedIcon from '../../images/likedIcon.png';
import CheckDate from './CheckDate';

const Date = ({sObj, onSubmit}) => {
    const isDisabled = sObj.checked;
    return (
        <div className={'ds-card-item date-item ' + (isDisabled ? 'date-item-disabled' : '' )}>
            <p>{sObj.title}</p>
            <div className='date-bottom-container'>
                <div className='action-container'>
                    <DeleteDate onSubmit={onSubmit} title={sObj.title} isDisabled={isDisabled} />
                    <LikeDate onSubmit={onSubmit} title={sObj.title} usersLike={sObj.users_like} isDisabled={isDisabled} />
                    <CheckDate onSubmit={onSubmit} title={sObj.title} check={sObj.checked} isDisabled={isDisabled}/>
                </div>
                <div className='liked-container'>
                    {
                    sObj?.users_like?.map((value,  index) => (
                        <img key={index} src={likedIcon} alt='like' className='like-logo' />
                    ))}    
                </div>
            </div>
        </div>
    );
};

export default Date;