import React from 'react';
import {config} from '../utils';
import CreateDate from '../Components/Date/CreateDate';
import RandomDate from '../Components/Date/RandomDate';
import Title from '../Components/utils/Title';
import AllDates from '../Components/Date/AllDates';

const DatePage = ({ onSubmit, refresh}) => {
    const createDateEndpoint = config.IS_LOVE ? config.API_CREATE_LOVE_DATE_ENDPOINT : config.API_CREATE_SPICY_DATE_ENDPOINT;
    const randomDateEndpoint = config.IS_LOVE ? config.API_RANDOM_LOVE_DATE_ENDPOINT : config.API_RANDOM_SPICY_DATE_ENDPOINT;

    return (
        <div id='date-page'>
            <Title text={config.IS_LOVE ? 'En amoureux ... ' : 'un soir chaud ...'} />
            <RandomDate endpoint={randomDateEndpoint}/>
            <AllDates key={refresh ? 'all-date-refresh' : 'all-date-no-refresh'} onSubmit={onSubmit} />
            <CreateDate endpoint={createDateEndpoint} onSubmit={onSubmit} />
        </div>
    );
};


export default DatePage;