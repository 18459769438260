import LogoutLogo from '../../images/logout.png';

const Logout = () => {

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('is_linked');
        window.location.href = '/';
    }

    return (
        <div className='logout-container'>
            <img
                src={LogoutLogo}
                alt="Logout"
                onClick={logout}
            />
        </div>
    );
}

export default Logout;