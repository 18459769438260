import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './style/css/main.css';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
      // Update data right there
    }, function(err) {
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);