import React, { useState, useEffect } from 'react';
import { config } from '../../utils';
import Info from '../utils/Info';
import Title from '../utils/Title';
import Form from '../utils/Form';

const CreateDate = ({endpoint, onSubmit}) => {
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [infoType, setInfoType] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setTitle('');
        try {
            const response = await fetch(config.API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                },
                body: JSON.stringify({
                    token: localStorage.getItem('token') ?? config.TOKEN,
                    title: title
                }),
            });
    
            if (response.ok) {
                setInfoType(config.INFO_TYPE.SUCCESS);
                setMessage('Le date a bien été crée');
                onSubmit();
            } else {
                throw new Error('Failed to create date');
            }
        } catch (error) {
            setInfoType(config.INFO_TYPE.ERROR);
            setMessage('Le date n\'as pas pu être crée');
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage('');
            }, 5000); // Hide message after 5 seconds

            return () => clearTimeout(timer);
        }
    }, [message]);

    return (
        <div className='create-date-container'>
            <Title text='Nouveau Date' />
            <Form 
                title={'Formulaire de date'}
                onSubmit={handleSubmit} 
                disabled={!title}
                content={'Envoyer un nouveau date via ce formulaire.'}
                buttonText={'Envoyer le date'}
                fields={
                    {
                        title: {
                            type: 'text',
                            value: title,
                            onChange: (e) => setTitle(e.target.value),
                            placeholder: 'Titre du date'
                        }
                    }
                }
            />
            {message && <Info infoType={infoType} clear={setMessage} text={message} />}
        </div>
    );
};

export default CreateDate;