import React from 'react';
import { config } from '../utils';
import SendSentenceButton from '../Components/Sentence/SendSentenceButton';
import AllSentences from '../Components/Sentence/AllSentences';
import Ranking from '../Components/Sentence/Ranking';
import CreateSentence from '../Components/Sentence/CreateSentence';
import Logout from '../Components/utils/Logout';


const SentencePage = ({ onSubmit, refresh}) => {
    const createSentenceEndpoint = config.IS_LOVE ? config.API_CREATE_LOVE_SENTENCE_ENDPOINT : config.API_CREATE_SPICY_SENTENCE_ENDPOINT;


    return (
        <div id='sentence-page'>
            <Logout/>
            <Ranking
                key = {refresh ? 'refresh-rank' : 'no-refresh-rank'}
                endpoint={
                    config.IS_LOVE
                        ? config.API_GET_LOVE_RANK_ENDPOINT
                        : config.API_GET_SPICY_RANK_ENDPOINT
                }
            />

            <SendSentenceButton
                endpoint={
                    config.IS_LOVE
                        ? config.API_GIVE_LOVE_ENDPOINT
                        : config.API_GIVE_SPICY_ENDPOINT
                }
                onSubmit={onSubmit}
            />

            <AllSentences
                key = {refresh ? 'refresh-sentences' : 'no-refresh-sentences'}
                endpoint={
                    config.IS_LOVE
                        ? config.API_GET_LOVE_ENDPOINT
                        : config.API_GET_SPICY_ENDPOINT
                }
            />

            <CreateSentence endpoint={createSentenceEndpoint} />
        </div>
    );
}

export default SentencePage;