import React, { useState, useEffect } from 'react';
import { config } from '../../utils';
import Info from '../utils/Info';

const SentenceButton = ({ onSubmit, endpoint }) => {
    const [countdown, setCountdown] = useState(0);
    const [message, setMessage] = useState('');
    const [infoType, setInfoType] = useState('');
    const [disabled, setDisabled] = useState(false);

    const isLoveButton = endpoint === config.API_GIVE_LOVE_ENDPOINT;

    useEffect(() => {
        const disabledTimestamp = localStorage.getItem('disabledTimestamp');
        if (disabledTimestamp) {
            const remainingTime = Math.max(5 - Math.floor((Date.now() - parseInt(disabledTimestamp)) / 1000), 0);
            if (remainingTime > 0) {
                setDisabled(true);
                setCountdown(remainingTime);
            } else {
                localStorage.removeItem('disabledTimestamp');
            }
        }
    }, []);

    const handleClick = async () => {
        if (!disabled) {
            try {
                const response = await fetch(config.API_URL + endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: localStorage.getItem('username') ?? config.USERNAME,
                        token: localStorage.getItem('token') ?? config.TOKEN,
                    }),
                });

                if (!response.ok) {
                    throw new Error('error sending message');
                }
                setInfoType(config.INFO_TYPE.SUCCESS);
                setMessage(isLoveButton ? 'Le message d\'amour a été envoyé' : 'Le message olé-olé a été envoyé');
                onSubmit();
                setDisabled(true);
                localStorage.setItem('disabledTimestamp', Date.now().toString());
                setCountdown(5); // Setting countdown to 60 seconds

            } catch (error) {
                setInfoType(config.INFO_TYPE.ERROR);
                setMessage(isLoveButton ? 'Erreur : L\'envoie d\'amour n\'as pas fonctionné' : 'Erreur : L\'envoie olé-olé n\'as pas fonctionné');
            }
        }
    };

    useEffect(() => {
        let timer;
        if (disabled) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown === 0) {
                        setDisabled(false);
                        localStorage.removeItem('disabledTimestamp');
                        clearInterval(timer);
                        setMessage('');
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000); // Update countdown every second
        }

        return () => clearInterval(timer);
    }, [disabled]);

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage('');
            }, 5000); // Hide message after 5 seconds
            return () => clearTimeout(timer);
        }
    }, [message]);

    return (
        <div className='send-button-container'>
            <button className={isLoveButton ? 'button-send-love-sentence' : 'button-send-spicy-sentence'} onClick={handleClick} disabled={disabled}>
                <p>{isLoveButton ? 'Donne lui du love ! :)' : 'Donne lui chaud ! ;)'}</p>
            </button>
            {disabled && <p>{countdown}s</p>}
            {message && <Info infoType={infoType} text={message} clear={setMessage}/> }
        </div>
    );
};

export default SentenceButton;