import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import AuthentForm from './Components/utils/AuthentFrom';
import AddFriendForm from './Components/utils/AddFriendForm';
import SentencesPage from './Pages/SentencesPage';
import DatesPage from './Pages/DatesPage';
import { config } from './utils';
import sentenceLogo from './images/sentence.png';
import dateLogo from './images/date.png';
import Lottie from 'lottie-react';
import loveRankLottie from './images/love-lottie.json';
import spicyRankLottie from './images/spicy-lottie.json';

function App() {
  const [refreshSentenceFlag, setRefreshSentenceFlag] = useState(false); 
  const [refreshRankFlag, setRefreshRankFlag] = useState(false); 
  const [refreshDatesFlag, setRefreshDatesFlag] = useState(false); 
  const [isLoveSentence, setIsLoveSentence] = useState(true);
  const token = localStorage.getItem('token') ?? config.TOKEN;

  useEffect(() => {
    const interval = setInterval(() => {
      refreshSentences();
      refreshRank();
      refreshDates();
    }, 1000 * 60 * 5);

    return () => clearInterval(interval);
  }, []);

  const refreshSentences = () => {
    console.log('Refreshing sentences');
    setRefreshSentenceFlag(!refreshSentenceFlag);
  };

  const refreshDates = () => {
    console.log('Refreshing dates');
    setRefreshDatesFlag(!refreshDatesFlag);
  };

  const refreshRank = () => {
    console.log('Refreshing rank sentences');
    setRefreshRankFlag(!refreshRankFlag);
  };

  if (!token) {
    // /register case
    if(window.location.pathname.split('/')[1]) {
      return <AuthentForm 
      endpoint={config.API_REGISTER_ENDPOINT}
      isRegister={true}
      title="Oh un inconnu !"
      content="Alors on se fait un compte ensemble ? ;)"
      buttonText="C'est parti !"
      headerTitle="Inscription !"
      onAuthSuccess={ () => {
        refreshSentences();
        refreshRank();
        refreshDates();
      }}
      />;
    } else {
      return <AuthentForm 
      endpoint={config.API_LOGIN_ENDPOINT}
      isRegister={false}
      headerTitle="Connexion !"
      title="Bienvenue !"
      content="Alors on se connecte toi et moi ? ;)"
      buttonText="C'est parti !"
      onAuthSuccess={ () => {
        refreshSentences();
        refreshRank();
        refreshDates();
      }}
    />;
    }
  }

  if (!config.IS_LINKED || !localStorage.getItem('isLinked')) {
    return <AddFriendForm
      onSubmit={ () => {
        refreshSentences();
        refreshRank();
        refreshDates();
      }}
    />
  }

  return (
    <Router>
      <div className={config.IS_LOVE ? 'love-mode' : 'spicy-mode'}>
      <Redirect to="/sentences" />
        <div id='navbar-container'>
          <button className='navbar-switch-button' onClick={() => {
            config.IS_LOVE = !isLoveSentence;
            setIsLoveSentence(!isLoveSentence)
            refreshSentences();
            refreshDates();
          }}>
            <Lottie className={'switch-lottie ' + (config.IS_LOVE ? 'love' : 'spicy')} animationData={ config.IS_LOVE ? loveRankLottie: spicyRankLottie}/>
          </button>
          <nav id='navbar'>
            <Link  className='navbar-item' to="/sentences"> <img src={sentenceLogo} alt='SentenceLogo' /> </Link>
            <Link  className='navbar-item' to="/dates"> <img src={dateLogo} alt='DateLogo' /> </Link>
          </nav>
        </div>
        

        <Switch>
          <Route path="/sentences">
            <SentencesPage
              refresh={refreshSentenceFlag}
              onSubmit={() => {
                refreshSentences();
              }}
            />
          </Route>
          <Route path="/dates">
            <DatesPage
              refresh={refreshDatesFlag}
              onSubmit={() => {
                refreshDates();
              }}
            />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;