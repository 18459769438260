import React from 'react';
import {getTimeAgoString} from '../../utils';
import clockLogo from '../../images/clock.png';

const Sentence = ({sentence, date}) => {
    return (
        <div className='ds-card-item item-sentence'>
        <p>{sentence}</p>
        <div className='sentence-item-time-container'>
            <img src={clockLogo} alt='time'/>
            <p>{getTimeAgoString(date)}</p>
        </div>
    </div>
    );
};

export default Sentence;
