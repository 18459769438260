import React from 'react';
import loveLogo from '../../images/love.png';
import spicyLogo from '../../images/spicy.png';
import { config } from '../../utils';

const Title = ({ text }) => {

    return (
        <div className='app-title'>
            <img src={config.IS_LOVE ? loveLogo : spicyLogo} alt='Logo' />
            <h1>{text}</h1>
            <img src={config.IS_LOVE ? loveLogo : spicyLogo} alt='Logo' />
        </div>
    )
};

export default Title;